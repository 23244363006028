import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import '../styles/globals.css';
import '../styles/typography.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en_lang from '../src/locales/en.json';
import vi_lang from '../src/locales/vi.json';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import ProviderWrapper from '../src/utils/store';
import smoothscroll from 'smoothscroll-polyfill';

let lang;
if (typeof window !== 'undefined') {
  lang = localStorage.getItem('lang') || 'vi';
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: en_lang },
      vi: { translation: vi_lang },
    },
    lng: lang || 'vi', // if you're using a language detector, do not define the lng option
    fallbackLng: 'vi',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = React.useState(() => new QueryClient());
  useEffect(() => {
    smoothscroll.polyfill();
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual';
    }
  }, []);
  return (
    <ProviderWrapper>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <Component {...pageProps} />
        </Hydrate>
      </QueryClientProvider>
    </ProviderWrapper>
  );
}

export default MyApp;
